import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import dateFormat from "dateformat";
 import { toast } from "react-toastify";
import DefaultImg from "../../assets/image/defaultimages/default.png";
import loaderimg from "../../assets/image/loader.gif";

const URL = process.env.REACT_APP_URL;

const ViewFranchise = () => {
  const [franchise, setfranchise] = useState([]);
  const [showloader, setShowLoader] = useState("none");

 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/users/getAllUser`);
    const Data1 = await getData;
    setfranchise(Data1.data.data);
    setShowLoader("none");
    console.log(Data1.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

const clearMobileToken = async (id)=>{
  const update = await axios.patch(
    `${URL}/admin/cleardevicetoken/${id}`,
     );
     if (update.status === 200) {
      toast.success("Data Update Successfully");
      getAllData();
      
    }
}

const enable_disable_order = async (id,number)=>{
 
  const update = await axios.patch(
    `${URL}/admin/enableDisableOrder/${id}?enable_disable_order=${number}`,
     );
     if (update.status === 200) {
      toast.success("Data Update Successfully");
      getAllData();
    }
}
const confirmCler = (id) => {
  confirmAlert({
    message: "Are you sure you want to Clear Mobile Token Data.",
    buttons: [
      {
        label: "Yes",
        onClick: () => clearMobileToken(id),
      },
      {
        label: "Cancel",
      },
    ],
  });
};
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">View franchises</h4>
              <Link to="/addfranchise" className="btn btn-primary">
                Add franchise
              </Link>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Block / Unblock Order</th>
                      <th>IMAGE</th>
                      <th>NAME</th>
                      <th>USER NAME</th>
                      <th>PHONE</th>
                      <th>DATE</th>
                      <th>DEVICE STATUS</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {franchise?.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1"> {index+1} )</td>
                          <td ><label class={`badge badge-${val?.enable_disable_order===1 ?'success':'danger'}`} onClick={ (e)=>{enable_disable_order(val._id,val?.enable_disable_order===1?2:1)}}>{val?.enable_disable_order===1?'Click To Disable':'Click To Enable'}</label></td>
                          <td class="py-1">
                            {val?.imageurl ? (
                              <img src={val?.imageurl} alt="images" />
                            ) : (
                              <img src={DefaultImg} alt="images"></img>
                            )}
                          </td>
                          <td>{val?.name}</td>
                          <td>{val?.username}</td>
                          <td>{val?.phone}</td>

                          <td>
                            {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                          </td>
                          <td>
                            {val.device_notification_token_id === "" ? (
                              <label class="badge badge-danger">
                              INACTIVE
                              </label>
                            ) : (
                              <label class="badge badge-success" onClick={()=>confirmCler(val._id)}>
                                ACTIVE 
                              </label> 
                            )}
                          </td>
                          <td>
                            {/* <Link
                              className="btn btn-primary"
                              to={`/viewfranchise_order/${val._id}`}
                            >
                              {" "}
                              <i class="fa-solid fa-eye menu-icon"></i>
                            </Link> */}
                            <Link
                              className="btn btn-primary"
                              to={`/viewfranchiseListOrder/${val._id}`}
                            >
                              {" "}
                              <i class="fa-solid fa-eye menu-icon"></i>
                            </Link>
                            <Link
                              className="btn btn-primary ml-2"
                              to={`/editfranchise/${val._id}`}
                            >
                              {" "}
                              <i class="fa-solid fa-edit menu-icon"></i>
                            </Link>
                            <Link
                              className="btn btn-primary ml-2"
                              to={`/consumptiondetail/${val._id}`}
                            >
                              {" "}
                              <i class="fa-solid fa fa-bar-chart menu-icon"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewFranchise;
