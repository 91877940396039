// todo 31/05/2023
const SendNotification = (token, notification) => {
  fetch("https://fcm.googleapis.com/fcm/send", {
    method: "POST", // POST method
    headers: {
      Authorization:
        "key=AAAAHOSqGdw:APA91bHVYRDo64kc4kL52OYYJNSXQ6UraTIA6E1_VL_7_ItedhWuxe49EoGvTc589d-eR5wd3qGLNkM1GnkFSpOgGaqY_MPRmzRBECL9xL1FoS202e6Dq51tQjzLixrxiafgjRvkaUu5",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      notification: notification,
      to: token,
    }),
  })
    .then((response) => {
      // success!!
      console.log(response);
    })
    .catch((error) => {
      // error :(
      console.log(error);
    });
};

export default SendNotification;
