import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoute = () => {
  const adminlocaltoken = localStorage.getItem("token");

  // if (!adminlocaltoken) {
  //   navigate("/login");
  //   toast.info("Acess Denied...");
  // }

  let auth = adminlocaltoken ? { token: true } : { token: false };
  return auth.token ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
