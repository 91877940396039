import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;

const initialState = {
  name: "",
  unit: "",
  price: "",
  gst:"",
};

const ViewItem = () => {
  const [values, setValues] = useState(initialState);
  const [item, setItems] = useState([]);

  // const [isShow, setIsShow] = useState(false);
  const [isEditClick, setisEditClick] = useState(false);
  const [editId, seteditId] = useState("");
  const [showloader, setShowLoader] = useState("none");
 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const AddData = async (userData) => {
    setShowLoader('block');
    const add = await axios.post(`${URL}/admin/createitem`, userData);
    if (add.status === 200) {
      toast.success("Data Added Successful...");
      getAllData();
    }
    setShowLoader('none');
    setValues({ name: "", unit: "", price: "" , gst:""});
  };
  const UpdateData = async (userData) => {
    setShowLoader('block');
    const update = await axios.patch(
      `${URL}/admin/updateitem/${editId}`,
      userData
    );
    setShowLoader('none');
    if (update.status === 200) {
      toast.success("Data Update Successful...");
      getAllData();
      setValues({ name: "", unit: "", price: "" , gst:""});
      setisEditClick(false);
      seteditId("");
    }
  };

  const onSubmit = (e) => {
   
    e.preventDefault();
    const { name, unit, price , gst} = values;
    if (!name || !unit || !price ||!gst) {
      toast.error("Please fill out all fields");
      return;
    }
    if (isEditClick) {
      UpdateData(values);
    } else {
      AddData(values);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value });
  };
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllItems`);
    const Data1 = await getData;
    setItems(Data1.data.data);
    setShowLoader("none");
    console.log(Data1.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const editItem = async (editid) => {
    const SingleData = await axios.get(`${URL}/admin/getsingleitem/${editid}`);
    const SingleData1 = await SingleData;
    setisEditClick(true);
    seteditId(editid);
    setValues(SingleData1.data.data);
    console.log(SingleData1.data);
  };

  // delete data start

  const delData = async (id) => {
    const response = await axios.patch(`${URL}/admin/deleteitem/${id}`);
    if (response.statusText === "OK") {
      toast.success("Delete Successful...");
      getAllData();
    }
  };

  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => delData(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  // delete data end

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* first */}

        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">ITEMS</h4>
              <p className="card-description">Total Items : {item.length}</p>
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>PRICE</th>
                      <th>UNIT</th>
                      <th>GST %</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td>{items.name}</td>
                          <td>{items.price} ₹</td>
                          <td>{items.unit}</td>
                          <td>{items.gst} %</td>
                          <td>
                            <i
                              class="fa-solid fa-edit menu-icon"
                              onClick={() => editItem(items._id)}
                            ></i>

                            <i
                              class="fa-solid fa-trash menu-icon ml-3"
                              onClick={() => confirmDelete(items._id)}
                            ></i>
                            {/* <p onClick={() => confirmDelete(items._id)}>Delete</p> */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* first */}

        {/*ADD FORM SECOND FORM */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {isEditClick ? "EDIT ITEM" : "ADD ITEM"}
              </h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">ITEM NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Item Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">ITEM PRICE</label>
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Item Price"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">ITEM UNIT</label>
                  <input
                    type="text"
                    onkeydown="return /[a-z]/i.test(event.key)"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Item unit"
                    name="unit"
                    value={values.unit}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">ITEM GST</label>
                  <input
                    type="text"
              
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Item GST"
                    name="gst"
                    value={values.gst}
                    onChange={handleChange}
                  />
                </div>
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/*ADD FORM SECOND FORM */}
      </div>
    </div>
  );
};

export default ViewItem;
