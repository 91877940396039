// todo 31/05/2023
import axios from 'axios';
const WhatsappSend = (number,message) => {
  const WHATSAPPID = process.env.REACT_APP_WHATSAPPWEB;

  //sachine sir  const token="649e52e8a3d3f";
   const token=process.env.REACT_APP_WHATSAPPWEB;;
 
    const numbers=`91${number}`;
     const config = {
       headers: {'Content-Type': 'application/json'}
   }
     let payload =JSON.stringify( {
       "token": token,
       data: [  {
         "number": numbers,
         "message": ` ${message}`
     }]
     });
     
     axios({
       url: 'http://www.wpadmin.star52app.com/api-panel/api/personalise_message.php',
       method: 'post',
       data: payload,
       config,
     })
     .then(function (response) {
        
         console.log(response);
     })
     .catch(function (error) {
        
         console.log(error);
     });
   
  };
  
  export default WhatsappSend;
  