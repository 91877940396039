import React, { useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Editquantity = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState('');
  const [amount, setAmount] = useState('');
  const [order, setOrder] = useState({
    user: {
      _id: "",
      name: "",
      username: "",
      email: "",
    },

    orderItems: [
      {
        itemid: {
          _id: "",
          name: "",
          unit: "",
        },
        qty: "",
        _id: "",
      },
    ],
  });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    
  };
  
useEffect(()=>{
  const getAllData = async () => {
    fetch(`${URL}/admin/getSingleOrderdetail/${id}`)
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setOrder(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getAllData();
},[]);
  return (
    <div className="content-wrapper">
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">EDIT ITEM QUANTITY</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                 
                {order?.orderItems.map((dat, index) => {
                  return(
                    <>
                    <label for="exampleInputUsername1">{dat?.itemid?.name}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Enter NAME"
                    // name="name"
                    // value={values.name}
                    // onChange={handleChange}
                  />
                    </>
                  )
                 }
                )};
               
                  {/* <label for="exampleInputUsername1">ITEM NAME</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Enter NAME"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  /> */}
                </div>
               
          
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  )
}

export default Editquantity