import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import loaderimg from "../../assets/image/loader.gif";

const URL = process.env.REACT_APP_URL;

const initialState = {
  title: "",
  number: "",
};

const MobileAlertStaff = () => {
  const [values, setValues] = useState([initialState]);
  const [values2, setValues2] = useState(initialState);
  const [isEditClick, setisEditClick] = useState(false);
  const [editid, seteditid] = useState("");
  const [showloader, setShowLoader] = useState("none");
 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getsingledata = async (id) => {
    setShowLoader('block');
    const get2 = await axios.get(`${URL}/admin/getsinglealertmobstaff/${id}`);
    const SingleData1 = await get2;
    setShowLoader('none');
    setValues2(SingleData1?.data?.data);
    console.log(SingleData1?.data?.data);
  };

  const EditData = async (id) => {
    setisEditClick("true");
    getsingledata(id);
    seteditid(id);
  };

  const getdata = async () => {
    setShowLoader('block');
    const getbank = await axios.get(`${URL}/admin/getalertmobstaff`);
    const SingleData1 = await getbank;
    setShowLoader('none');
    setValues(SingleData1?.data?.data);
    console.log(SingleData1?.data?.data);
  };

  useEffect(() => {
    getdata(); // eslint-disable-next-line
  }, []);

  const insertdata = async (values) => {
    const url = `${URL}/admin/createalertmobstaff`;
    await axios
      .post(url, values)
      .then((result) => {
        toast.success("Data Updated Successful...");
        setValues2({ title: "", number: "" });
        getdata();
      })
      .catch(() => {
        toast.error("Failed...");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { title, number } = values2;

    if (!title || !number) {
      toast.error("Please fill out all fields");
      return;
    }

    {
      isEditClick ? (updatedata(values2)) : (insertdata(values2));
    }
  };

  const updatedata = async (data) => {
    setShowLoader('block');
    const url = `${URL}/admin/updatesinglealertmobstaff/${editid}`;
    await axios
      .patch(url, data)
      .then((result) => {
        toast.success("Data Updated Successful...");
        setisEditClick("");
        getdata();
        setValues2({ title: "", number: "" });
        setShowLoader('none');
      })
      .catch(() => {
        toast.error("Failed...");
      });
  };

  const delData = async (id) => {
    const response = await axios.delete(
      `${URL}/admin/deletesinglealertmobstaff/${id}`
    );
    if (response.status === 200) {
      toast.success("Delete Successful...");
      getdata();
    }
  };

  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => delData(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues2({ ...values2, [name]: value });
  };

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>

      <div className="row">
        {/* ONE START */}
        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">CONTACT NUMBERS</h4>
              <p className="card-description"></p>
              <div className="table-responsive">
                <table className="table" id="datatable">
                  <thead>
                    <tr>
                      <th>TITLE</th>
                      <th>NUMBER</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values?.map((alt, index) => {
                      return (
                        <tr key={index}>
                          <td>{alt?.title}</td>
                          <td>{alt?.number}</td>

                          <td>
                            <i
                              class="fa-solid fa-edit menu-icon"
                              style={{ marginLeft: "10px" }}
                              onClick={() => EditData(alt._id)}
                            ></i>
                            <i
                              class="fa-solid fa-trash menu-icon"
                              style={{ marginLeft: "10px" }}
                              onClick={() => confirmDelete(alt._id)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* ONE END */}

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {isEditClick ? "UPDATE CONTACT NUMBER" : "ADD CONTACT NUMBER"}
              </h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Add Title"
                    name="title"
                    value={values2?.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Mobile Number"
                    name="number"
                    value={values2?.number}
                    onChange={handleChange}
                    pattern="[1-9]{1}[0-9]{9}"
                    maxLength={10}
                  />
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAlertStaff;
