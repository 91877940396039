import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";


import "react-confirm-alert/src/react-confirm-alert.css";


import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;


const Consumptiondetail = () => {
  const { id } = useParams(); // userid he


  const [item, setItem] = useState([
  ]);
  // const [consumption, setConsumption] = useState([]);


  const [showloader, setShowLoader] = useState("none");
  const [month, setMonth] = useState("0");
  const HandleMonth = (e) => {
    const value = e.target.value;
    setMonth(value);
    getAllData(value);
  };


  const getAllData = async (value) => {
    setShowLoader("block");
    //  const month =1;
    const url = `${URL}/admin/getconsumptionrecord/${id}/${value}`
    await axios
      .get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then(({ data }) => {
        setShowLoader("none");


        setItem(data?.data);
        console.log(data);
      });
  };


  useEffect(() => {
    getAllData(0);


  }, []);


  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title"> CONSUMPTION DATA</h4>
              <p> <div className="form-group">
                <label for="exampleInputUsername1">Select old Month</label>
                <select
                  className="form-control"
                  id="exampleSelectGender"
                  name="month"
                  onChange={HandleMonth}
                  value={month}
                >
                  <option>Select</option>
                  <option value="1">1 Month</option>
                  <option value="3">3 Month</option>
                  <option value="6">6 Month</option>
                  <option value="12">12 Month</option>
                  <option value="0">All Data</option>
                </select>
              </div>
              </p>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>ITEM NAME</th>
                      <th>UNIT</th>


                    </tr>
                  </thead>
                  <tbody>


                    {
                      item
                        .map((val, index) => {
                          return (
                            <tr key={index}>
                              <td className="py-1"># {index + 1}</td>
                              <td>{val?.item.name}</td>
                              <td>{val?.qty}</td>


                            </tr>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};


export default Consumptiondetail;