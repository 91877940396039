import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
import SendNotification from "../../utils/SendNotification";
import WhatsappSend from "../../utils/WhatsappSend";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import ReactToPrint from "react-to-print";
import dateFormat from "dateformat";
const URL = process.env.REACT_APP_URL;

const EditOrder = () => {
  const { id } = useParams();
  const [devicetoken, setDevicetoken] = useState("");
  const [showloader, setShowLoader] = useState("none");
  const [showDiv, setShowDiv] = useState("none");
  const [showPayBtn, setShowPayBtn] = useState("none");
  const [tranjectionDetail, setTranjectionDetail] = useState("");
  const [courierrecept, setcourierrecept] = useState("");
  const [invoice, setInvoice] = useState("");
  const [statushistory, setStatushistory] = useState([]);
  const [order, setOrder] = useState({
    user: {
      _id: "",
      name: "",
      username: "",
      email: "",
    },

    orderItems: [
      {
        itemid: {
          _id: "",
          name: "",
          unit: "",
        },
        qty: "",
        _id: "",
      },
    ],
  });

  // Edit item start =========
  const [userid, setuserid] = useState("");
  const [isedititem, setisedititem] = useState("none");
  const [edititemname, setEdititemname] = useState("");
  const [edititemid, setEdititemid] = useState("");
  const [edititemqty, setEdititemqty] = useState("");
  // Edit item End =========
  
  const getAllData = async () => {
    setShowLoader("block");
    fetch(`${URL}/admin/getSingleOrderdetail/${id}`)
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setOrder(data?.data);
        setStatushistory(data?.statushistory);
        setShowLoader("none");
        setShowDiv("block");
        setDevicetoken(data?.data?.user?.device_notification_token_id); //set device token
setuserid(data?.data?.user?._id);
        // For Pdf start 07/07/2023
        // setPdfordernumber(order?.orderNumber);
        // setpdfusername(order?.user?.name);
        // setpdfphone(order?.user?.phone);
        // setpdforderItems(order?.orderItems);
        // setpdforderItemslength(order?.orderItems?.length);

        // For Pdf End 07/07/2023
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQrCode = async () => {
    //  const getqr = await axios.get(`${URL}/admin/getsingleQr`);
    // const getdata = await getqr;
    // setpdfqrimage(getdata?.data?.data[0]?.image);
    //  setQr(getdata?.data?.data);
    // console.log(getdata?.data?.data);
  };
  useEffect(() => {
    getAllData();
    getQrCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOrderStatus = async (e) => {
    // handleDownload();
    const msg = e.target.value;
    const phone = order?.user?.phone;
    //whatsapp(phone,msg);
    const length = order?.orderItems?.length;

    var tester = [];
    let i;
    for (i = 0; i <= length - 1; i++) {
      tester.push(order?.orderItems[i].itemid.name);
    }
    const msg2 = `ORDER STATUS IS ${msg} ,Total Items Quantity :${length} , Your Order Item : ${tester}`;
    WhatsappSend(phone, msg2);

    SendNotification(devicetoken, {
      title: `YOUR ORDER IS -${e.target.value}`,
      body: `ORDER STATUS : -${e.target.value}`,
      mutable_content: true,
      sound: "Tri-tone",
    }); // send notification on change
    const value = e.target.value;
    const patchdata = await axios.patch(
      `${URL}/admin/updateOrderStatus/${id}`,
      { isDeliveredStatus: value }
    );
    if (patchdata?.status === 200) {
      toast.success("Update Successful...");
      getAllData();
    }
  };

  useEffect(() => {
    if (tranjectionDetail !== null) {
      setShowPayBtn("block");
    }
    if (tranjectionDetail === "") {
      setShowPayBtn("none");
    }
  }, [tranjectionDetail]);

  const handlePaymentStatus = async (e) => {
    const paymentby = tranjectionDetail;
    if (paymentby === "") {
      toast.error("Tranjection Detail Required ");
      return;
    }

    const patchdata = await axios.patch(
      `${URL}/admin/updatePaymentStatus/${id}`,
      { paymentMethod: paymentby }
    );

    if (patchdata?.status === 200) {
      setShowPayBtn("none");
      toast.success("Update Successful...");
      getAllData();

      const phone = order?.user?.phone;
      const msg2 = `Dear Customer, Payment Accepted Successfully for Your Order Number : ${order?.orderNumber}`;
      WhatsappSend(phone, msg2);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setTranjectionDetail(value);
  };

  const handleChange_courierrecept = (e) => {
    setcourierrecept(e.target.files[0]);
  };

  const SubmitRecept = () => {
    if (!courierrecept) {
      toast.error("Please Select Courier Recept...");
      return;
    }
    const url = `${URL}/admin/courierrecept/uploadcourierrecept/${id}`;
    const config = {
      headers: {
        "content-type": "multipart-formdata",
      },
    };

    const formData = new FormData();
    formData.append("file", courierrecept);
    axios
      .patch(url, formData, config)
      .then((result) => {
        console.log(result.data);
        toast.success("Uploads Successfully...");
        setcourierrecept("");
      })
      .catch((error) => {
        alert("service error");
        console.log(error);
      });
  };
  const handleChange_invoice = (e) => {
    setInvoice(e.target.files[0]);
  };
  const SubmitInvoice = () => {
    if (!invoice) {
      toast.error("Please Select Invoice...");
      return;
    }

    const url = `${URL}/admin/uploadinvoices/uploadinvoic/${id}`;
    const config = {
      headers: {
        "content-type": "multipart-formdata",
      },
    };

    const formData = new FormData();
    formData.append("file", invoice);
    axios
      .patch(url, formData, config)
      .then((result) => {
        console.log(result.data);
        toast.success("Uploads Successfully...");
        setcourierrecept("");
      })
      .catch((error) => {
        alert("service error");
        console.log(error);
      });
  };

// item quantity work Start=>
  const editItemQuantity = (id,name,qty) => {
    setisedititem('block');
    setEdititemid(id);
    setEdititemname(name);
    setEdititemqty(qty);
  };
  const itemquantityclear = () => {
    setisedititem('none');
    setEdititemid('');
    setEdititemname('');
    setEdititemqty('');
  };

 const handleChange_itemquantity = (e) =>{
  setEdititemqty(e.target.value);
 };

 const Submit_update_item_quantity = () => {

  // alert(id);
  // alert(userid);
  // alert(edititemid);
  // alert(edititemqty);

    if (!edititemid) {
      toast.error("NO Item Id...");
      return;
    }
    if (!edititemqty) {
      toast.error("Enter Quantity.");
      return;
    }
    const url = `${URL}/admin/editsingleitemquantity/${id}`;
   const formData = {orderid: id,userid: userid,itemid: edititemid,itemquantity:edititemqty}
   //const formData = new FormData();

    // formData.append("orderid", id);
    // formData.append("userid", userid);
    // formData.append("itemid", edititemid);
    // formData.append("itemquantity", edititemqty);
   
    axios
      .patch(url, formData)
      .then((result) => {
        console.log(result.data);
        toast.success("Update Successfully...");
        itemquantityclear();
        getAllData();
      })
      .catch((error) => {
        alert("service error");
        console.log(error);
      });
  };
  // const [edititemname, setEdititemname] = useState("");
  // const [edititemid, setEdititemid] = useState("");
  // const [edititemqty, setEdititemqty] = useState("");
 // item quantity work End=>
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="img" className="loaderImage" />
      </div>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">UPLOAD COURIER RECEPT</h4>
              <div className="row ">
                <div className="col-md-8  mb-2">
                  {" "}
                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputUsername1"
                    onChange={handleChange_courierrecept}
                    name="file"
                  />
                </div>
                <div className="col-md-3 ">
                  {" "}
                  <button
                    onClick={SubmitRecept}
                    className="btn btn-primary mr-2 "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">UPLOAD INVOICE</h4>
              <div className="row ">
                <div className="col-md-8  mb-2">
                  {" "}
                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputUsername1"
                    onChange={handleChange_invoice}
                    name="file"
                  />
                </div>
                <div className="col-md-3 ">
                  {" "}
                  <button
                    onClick={SubmitInvoice}
                    className="btn btn-primary mr-2 "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
{/* row to edit item 03/08/2023 start*/}
<div className="row"  style={{ display: isedititem }}>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Item Quantity</h4>
              <p>{edititemname}</p>
              <div className="row ">  
                <div className="col-md-8  mb-2">
                
                  <input
                    type="number"
                    className="form-control"
                    id="exampleInputUsername1"
                    value={edititemqty}
                    onChange={handleChange_itemquantity}
                    name="itemquantity"
                  />
                </div>
                <div className="col-md-3 ">
                  {" "}
                  <button
                    onClick={Submit_update_item_quantity}
                    className="btn btn-primary mr-2 "
                  >
                    Submit
                  </button>
                  <button
                    onClick={itemquantityclear}
                    className="btn btn-primary mr-2 "
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

       
      </div>

{/* row to edit item End 03/08/2023 End*/}


      <div className="row">
        {/* // start */}

        <div
          className="col-md-6 grid-margin stretch-card"
          style={{ display: showDiv }}
        >
          <div className="card">
            <div className="card-body">
              <div className="table-responsive" id="collapse1">
                <table className="table ">
                  <tbody>
                    <tr>
                      <th scope="row">ORDER NO :</th>
                      <td> {order?.orderNumber}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <th scope="row">TOTAL AMOUNT (INC GST):</th>
                      <td>{Math.round(order?.totalamount)}</td>
                    </tr>
                    <tr>
                      <th scope="row">PAYMENT STATUS:</th>
                      <td>{order?.isPaid ? "PAID" : "PENDING"}</td>
                    </tr>
                    {order?.isPaid ? (
                      <tr>
                        <th scope="row">PAYMENT DETAIL:</th>
                        <td>
                          <textarea
                            rows={4}
                            type="text"
                            readOnly
                            className="form-control"
                          >
                            {order?.paymentMethod}
                          </textarea>
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <th scope="row">ORDER BY :</th>
                      <td> {order?.user?.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">PHONE NO :</th>
                      <td> {order?.user?.phone}</td>
                    </tr>
                    <tr>
                      <th scope="row">ORDER STATUS :</th>
                      <td> {order?.isDeliveredStatus}</td>
                    </tr>

                    <tr>
                      <th scope="row">Update Order Status :</th>
                      <td>
                        <select
                          className="form-control"
                          id="exampleSelectGender"
                          name="isDeliveredStatus"
                          onChange={handleOrderStatus}
                        >
                          <option>Select</option>
                          <option value="PENDING">PENDING</option>
                          <option value="PROCESSING">PROCESSING</option>
                          <option value="SHIPPED">SHIPPED</option>
                          <option value="DELIVERED">DELIVERED</option>
                          <option value="REJECTED">REJECTED</option>
                        </select>
                      </td>
                    </tr>
                    {showPayBtn === "block" ? (
                      <tr>
                        <th scope="row">Update Payment Status :</th>
                        <td>
                          <select
                            className="form-control"
                            id="exampleSelectGender"
                            name="isPaid"
                            onChange={handlePaymentStatus}
                          >
                            <option>Select</option>
                            <option value="PAID">PAID</option>
                          </select>
                        </td>
                      </tr>
                    ) : null}
                    {order?.isPaid ? null : (
                      <tr>
                        <th scope="row">Remark/Payment :</th>
                        <td>
                          <textarea
                            rows={4}
                            type="text"
                            className="form-control"
                            id="exampleInputUsername1"
                            placeholder="Enter Tranjection Detail"
                            name="paymentby"
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>{" "}
            </div>
          </div>
        </div>
        {/* https://stackoverflow.com/questions/57383218/how-to-iterate-through-an-array-of-objects-from-json-in-react-js */}
        <div
          className="col-md-6 grid-margin stretch-card"
          style={{ display: showDiv }}
        >
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">ORDERED PRODUCTS DETAILS</h4>
              {/* <p>Total Item Order : {order?.totalitems}</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>QUANTITY</th>

                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.orderItems.map((dat, index) => {
                      return (
                        <tr key={index}>
                          <td>{dat?.itemid?.name}</td>

                          <td>
                            {dat?.qty} . {dat?.itemid?.unit}
                          </td>
                          <td>
                            <i
                              class="fa-solid fa-edit menu-icon"
                              onClick={() => editItemQuantity(dat?.itemid?._id,dat?.itemid?.name,dat?.qty)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {/* {order?.orderItems.map((dat, index) => {
                return (
                  <ul key={index}>
                    <li>
                   <tr></tr>
                      <p>Name : {dat?.itemid?.name}</p>
                      <p>
                        Quantity : {dat?.qty} . {dat?.itemid?.unit}{" "}
                      </p>
                    </li>
                  </ul>
                );
              })} */}
              {/* <Link
                              className="btn btn-primary"
                              to={`/editquantity/${id}`}
                            >
                              Edit Quantity
                            </Link> */}
            </div>
          </div>
        </div>

        <div
          className="col-md-6 grid-margin stretch-card"
          style={{ display: showDiv }}
        >
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">ORDERED PROCESS DETAILS</h4>

              {statushistory?.map((dat, index) => {
                return (
                  <ul key={index}>
                    <li>
                      {" "}
                      <p>DeliveredStatus : {dat?.deliveredStatus}</p>
                      Date :{" "}
                      {dateFormat(
                        `${dat?.createdAt}`,
                        "mmmm dS, yyyy HH:MM"
                      )}{" "}
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>

        {/* // end */}
      </div>
    </div>
  );
};

export default EditOrder;
