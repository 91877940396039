import React from "react";
import { useState,  } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import Logo from "../assets/image/logo.png";
const URL = process.env.REACT_APP_URL;
//const URL = "http://localhost:5000/api/v1";
const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const [localStorageValue, SetlocalStorageValue] = useState("");

  // check is already token available or not
  // =================end check check
  // useEffect(()=>{

  //   const adminlocaltoken = localStorage.getItem('admin');

  //   if(adminlocaltoken){
  //     navigate("/AllMovies");
  //     toast.info("Redirected because you are login...");
  //   }

  // },[])
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const AddData = async (userData) => {
    try {
      const add = await axios.post(`${URL}/admin/login`, userData);
      if (add.status === 403) {
        toast.error("Account Blocked Please Contact Admin...");
      }
      if (add.status === 200) {
        const okcheck = await add.data;
        console.log(okcheck);
        localStorage.setItem("token", okcheck.token);
        localStorage.setItem("admin", okcheck.token);
        localStorage.setItem("permission", okcheck.admindata.permission);
        localStorage.setItem("adminid", okcheck.admindata._id);
        localStorage.setItem("loginType", okcheck.admindata.loginType);
        // sessionStorage.setItem("token", okcheck.token);
        // sessionStorage.setItem("admin", okcheck.token);
        // sessionStorage.setItem('token', JSON.stringify(data.token));
        SetlocalStorageValue(localStorage.getItem("sessiondata"));
        navigate("/dashboard");
        toast.success("LoginSuccessful...");
      }
    } catch (error) {
      if(error.response.status === 403)
      {
        toast.error("Account Blocked");
      }else{
        toast.error("Details Not Match...");
      }
      
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const { email, password } = values;
    if (!email || !password) {
      toast.error("All Fields Required");
      return;
    }
    console.log(values);
    AddData(values);
  };
  
  return (
    
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo text-center">
                    <img src={Logo} alt="logo" />
                  </div>
                  {localStorageValue?'':''}
                  {/* <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6> */}
                  <form className="pt-3" onSubmit={onSubmit}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mt-3">
                      <button
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                        type="submit"
                      >
                        SIGN IN
                      </button>
                    </div>
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      {/*  <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          Keep me signed in
                        </label>
                      </div> 
                      <Link className="auth-link text-black">
                        Forgot password?
                      </Link> */}
                    </div>

                    <div className="text-center mt-4 font-weight-light">
                      Forget Password ?{" "}
                      <Link to='/forgetpassword' className="text-primary">Click Here</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
