import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import axios from "axios";
import loaderimg from "../../assets/image/loader.gif";
import dateFormat from "dateformat";

const URL = process.env.REACT_APP_URL;

const ViewFranchiseOrderDetail = () => {
  const { id } = useParams();
  const [showloader, setShowLoader] = useState("none");
  const [showDiv, setShowDiv] = useState("none");
  // const [orders, setOrder] = useState([{}]);
  const [orders, setOrder] = useState([
    {
      _id: "",
      user: {
        _id: "",
        name: "",
        username: "",
        email: "",
        image: "",
        phone: "1234567891",
        password: "",
        notification: [
          {
            notificationid: "",
            readstatus: false,
            _id: "",
          },
        ],
        createdAt: "",
        updatedAt: "",
        __v: 0,
      },
      orderNumber: "",
      orderItems: [
        {
          itemid: {
            _id: "",
            name: "",
            unit: "",
            deleteStatus: false,
            isChecked: false,
            createdAt: "",
            updatedAt: "",
            __v: 0,
          },
          qty: "",
          _id: "",
        },
      ],
      totalitems: "3",
      isPaid: false,
      paymentMethod: "",
      isDeliveredStatus: "",
      createdAt: "",
      updatedAt: "",
      __v: 0,
    },
  ]);
 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
    setShowLoader("block");
    fetch(`${URL}/users/getAllOrderByUserId/${id}`)
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data?.data);
        setOrder(data?.data);
        setShowLoader("none");
        setShowDiv("block");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="img" className="loaderImage" />
      </div>
      <div className="row" style={{ display: showDiv }}>
        <div class="col-12 col-xl-8 mb-8 mb-xl-0">
          <h3 class="font-weight-bold">Total Order : {orders?.length}</h3>
          <h6 class="font-weight-normal mb-0">
            <span class="text-danger"> </span>
          </h6>
        </div>

        {orders?.map((order, index) => {
          return (
            <div className="row col-md-12  mb-3">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <table class="table">
                      <tbody key={order?.orderNumber}>
                        <tr>
                          <th scope="row">ORDER NO :</th>
                          <td> {order?.orderNumber}</td>
                        </tr>
                        <tr>
                          <th scope="row">ORDER DATE :</th>
                          <td>
                            {" "}
                            {dateFormat(`${order?.createdAt}`, "mmmm dS, yyyy")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">ORDER AMOUNT :</th>
                          <td> {order?.totalamount}</td>
                        </tr>
                        <tr>
                          <th scope="row">PAYMENT STATUS:</th>
                          <td>{order?.isPaid ? "PAID" : "PENDING"}</td>
                        </tr>
                        <tr>
                          <th scope="row">ORDER BY :</th>
                          <td> {order?.user?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">PHONE NO :</th>
                          <td> {order?.user?.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">ORDER STATUS :</th>
                          <td> {order?.isDeliveredStatus}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">ORDERED PRODUCTS DETAILS</h4>
                    <p>Total Item Order : {order?.totalitems}</p>
                    {order?.orderItems?.map((dat, index2) => {
                      return (
                        <>
                          <ul>
                            <li key={dat?.itemid?.name}>
                              <p>Name : {dat?.itemid?.name}</p>
                              <p>
                                Quantity : {dat?.qty} . {dat?.itemid?.unit}{" "}
                              </p>
                            </li>
                          </ul>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* // start */}
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewFranchiseOrderDetail;
