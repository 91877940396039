import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ isActive,onClick}) => {
  const permission = localStorage.getItem("permission");

  const x = permission;

  return (
    <nav
      class={`sidebar sidebar-offcanvas ${
        isActive === "active" ? "active" : ""
      }`}
      id="sidebar"
    >
    
      <ul class="nav">
        <li class="nav-item active">
          <Link class="nav-link" to="/dashboard"  onClick={onClick}>
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">Dashboard</span>
          </Link>
        </li>
        {x.includes("1") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/vieworder"  onClick={onClick}>
              <i class="fa fa-shopping-cart menu-icon"></i>
              <span class="menu-title">ORDERS</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {x.includes("2") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/viewfranchise"  onClick={onClick}>
              <i class="fa-solid fa-user-group menu-icon"></i>
              <span class="menu-title">FRANCHISE</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {x.includes("3") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/viewstaff"  onClick={onClick}>
              <i class="fa-solid fa-user-group menu-icon"></i>
              <span class="menu-title">SUB STAFF</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {x.includes("4") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/viewitem"  onClick={onClick}>
              <i class="fa-solid fa-list menu-icon"></i>
              <span class="menu-title">ITEMS</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {x.includes("5") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/viewpdf"  onClick={onClick}>
              <i class="fa-solid fa-file-pdf menu-icon"></i>
              <span class="menu-title">PDF</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {x.includes("6") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/viewnotification"  onClick={onClick}>
              <i class="fa-solid fa-bell menu-icon"></i>
              <span class="menu-title">NOTIFICATION</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {x.includes("7") ? (
          <li class="nav-item active"  onClick={onClick}>
            <Link class="nav-link" to="/viewpages">
              <i class="fa-solid fa fa-file menu-icon"></i>
              <span class="menu-title">PAGES</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {x.includes("8") ? (
        <li class="nav-item active">
            <Link class="nav-link" to="/qrcode"  onClick={onClick}>
              <i class="fa-solid fa fa-qrcode menu-icon"></i>
              <span class="menu-title">QR CODE</span>
            </Link>
          </li>
          ) : (
          ""
        )}
        {x.includes("9") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/feedback"  onClick={onClick}>
              <i class="fa-solid fa fa-comments menu-icon"></i>
              <span class="menu-title">FEEDBACK</span>
            </Link>
          </li>
          ) : (
          ""
        )}
        {x.includes("10") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/bankdetail"  onClick={onClick}>
              <i class="fa-solid fa fa-bank menu-icon"></i>
              <span class="menu-title">BANK DETAIL</span>
            </Link>
          </li>
          ) : (
          ""
        )}
        {x.includes("11") ? (
          <li class="nav-item active">
            <Link class="nav-link" to="/alertmobilestaff"  onClick={onClick}>
              <i class="fa-solid fa fa-mobile menu-icon"></i>
              <span class="menu-title">MOBILE NO AlERT </span>
            </Link>
          </li>
          ) : (
          ""
        )}
         

      </ul>
    </nav>
  );
};

export default Sidebar;
