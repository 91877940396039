import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import "react-confirm-alert/src/react-confirm-alert.css";
import dateFormat from "dateformat";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;

const ViewNotification = () => {
  const [notification, setnotification] = useState([]);

  const [showloader, setShowLoader] = useState("none");
 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllNotification`);
    const Data1 = await getData;
    setnotification(Data1.data.data);
    setShowLoader("none");
    console.log(Data1.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">View Notifications</h4>
              <Link to="/addnotification" className="btn btn-primary">
                Add Notification
              </Link>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>ID</th>
                      {/* <th>IMAGE</th> */}
                      <th>TITLE</th>

                      <th>DESCRIPTION</th>
                      <th>DATE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notification?.map((val, index) => {
                      return (
                       <>
                        {/* {val?.notificationforSingleuser === "false" ? ( */}
                        <tr key={index}>
                        
                        <td className="py-1"># {index}</td>
                        {/* <td class="py-1">
                          <img src={val?.imageurl} alt="img" />
                        </td> */}
                        <td>{val?.title}</td>

                        <td>{val?.description.substring(0, 30)} ...</td>
                        <td>
                          {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                        </td>
                        <td>
                          <Link
                            className="btn btn-primary"
                            to={`/editnotification/${val._id}`}
                          >
                           
                            <i class="fa-solid fa-edit menu-icon"></i>
                          </Link>
                        </td>
                      </tr>
                       {/* ): null } */}
                       
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewNotification;
