import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";

const URL = process.env.REACT_APP_URL;

const initialstate = {
  name: "",
  username: "",
  email: "",
  password: "",
  phone: "",
  companyname: "",
  gstnumber: "",
  address: "",
};

const AddFranchise = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  const [showloader, setShowLoader] = useState("none");
  
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  // const [image, setImage] = useState("");

  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  // };

  const CreateData = async () => {
    const url = `${URL}/users/register`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const formData = new FormData();

const str = values.username;
const usernm = str.replace(/\s+/g,'').toLowerCase();

    // formData.append("file", image);
    formData.append("name", values.name);
    formData.append("username", usernm);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("phone", values.phone);
    formData.append("companyname", values.companyname);
    formData.append("gstnumber", values.gstnumber);
    formData.append("address", values.address);
    setShowLoader("block");
    axios
      .post(url, formData, config)
      .then((result) => {
        console.log(result.data);
        toast.success("Data Add Successful...");
        navigate("/viewfranchise");
        setShowLoader("none");
      })
      .catch((error) => {
        alert("Data Already Exist (EMAIL / USERNAME)");
        console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, username, email, phone,password, companyname, gstnumber, address } =
      values;

    if (
      !name ||
      !username ||
      !email ||
      !phone ||
      !password ||
      !companyname ||
      !gstnumber ||
      !address
    ) {
      toast.error("Please fill out all fields");
      return;
    }
    CreateData(values);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className="content-wrapper">
    <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">ADD FRANCHISE</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">NAME</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter NAME"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">USER NAME</label>
                      <div class="col-sm-9">
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter User Name"
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">Email</label>
                      <div class="col-sm-9">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">PASSWORD</label>
                      <div class="col-sm-9">
                        <input
                          type="password"
                          placeholder="Password"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">PHONE</label>
                      <div class="col-sm-9">
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          pattern="[1-9]{1}[0-9]{9}"
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">
                        COMPANY NAME
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="companyname"
                          value={values.companyname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">GST NUMBER</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="gstnumber"
                          value={values.gstnumberone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">ADDRESS</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default AddFranchise;
