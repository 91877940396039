import React from "react";
// import Logo from "../../assets/image/logo.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;

const Dashboard = () => {
  const permission = localStorage.getItem("permission");
 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const x = permission;
  console.log(x);
  const [value, setValue] = useState({
    Notificationdata: "",
    pendingorder: "",
    user: "",
    items: "",
    unpaidorder: "",
  });
  const [showloader, setShowLoader] = useState("none");

  const getPendingOrder = async () => {
    setShowLoader("block");
    const PendingData = await axios.get(`${URL}/admin/getDashboardDetail/`);

    const PendingData1 = await PendingData;
    setValue(PendingData1.data);
    setShowLoader("none");
  };

  useEffect(() => {
    getPendingOrder();
  }, []);

  return (
    <>
      {/* main page start */}
      <div class="content-wrapper">
        <div className="loader-container " style={{ display: showloader }}>
          <img src={loaderimg} alt="" className="loaderImage" />
        </div>
        <div class="row">
          <div class="col-md-12 grid-margin">
            <div class="row">
              <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                <h3 class="font-weight-bold">Welcome </h3>
                <h6 class="font-weight-normal mb-0">
                  All Orders are running smoothly! You have{" "}
                  <span class="text-danger">
                    {value.pendingorder} Pending Orders!
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 grid-margin transparent">
            <div class="row">
              {x.includes("1") ? (
                <div class="col-md-3 mb-4 stretch-card transparent">
                  {" "}
                  <div class="card card-tale">
                    <div class="card-body text-center">
                      <Link to="/vieworder" className="text-white">
                        {" "}
                        <p class="mb-4">TOTAL PENDING ORDERS</p>
                        <p class="fs-30 mb-2 text-center">
                          {value.pendingorder}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
              {x.includes("1") ? (
                <div class="col-md-3 mb-4 stretch-card transparent">
                  {" "}
                  <div class="card card-tale">
                    <div class="card-body text-center">
                      <Link to="/vieworder" className="text-white">
                        {" "}
                        <p class="mb-4">TOTAL UNPAID ORDERS</p>
                        <p class="fs-30 mb-2 text-center">
                          {value.unpaidorder}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
              {x.includes("2") ? (
                <div class="col-md-3 mb-4 stretch-card transparent">
                  <div class="card card-tale">
                    <div class="card-body text-center">
                      <Link to="/viewfranchise" className="text-white">
                        {" "}
                        <p class="mb-4 ">TOTAL FRANCHISE'S</p>
                        <p class="fs-30 mb-2 text-center">{value.user}</p>
                      </Link>
                      {/* <p>10.00% (30 days)</p> */}
                    </div>
                  </div>
                </div>
              ) : null}
              {x.includes("4") ? (
                <div class="col-md-3 mb-4 stretch-card transparent">
                  <div class="card card-tale">
                    <div class="card-body text-center">
                      <Link to="/viewitem" className="text-white">
                        {" "}
                        <p class="mb-4 ">TOTAL ITEMS</p>
                        <p class="fs-30 mb-2 text-center">{value.items}</p>
                      </Link>
                      {/* <p>10.00% (30 days)</p> */}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* main page end */}
    </>
  );
};

export default Dashboard;
