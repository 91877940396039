import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
// import confirmAlert from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// import { toast } from "react-toastify";
import dateFormat from "dateformat";
// import $ from "jquery";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;

const ViewFranchiseListOrder = () => {
  const [order, setOrder] = useState([]);
  const [showloader, setShowLoader] = useState("none");

  const { id } = useParams();

 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/users/getAllOrderByUserId/${id}`);
    const Data1 = await getData;
    setOrder(Data1.data.data);
    setShowLoader("none");
    console.log(Data1.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">VIEW ORDERS</h4>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      {/* <th>USER NAME</th> */}
                      <th>PHONE</th>
                      <th>ORDER NUMBER</th>
                      <th>ORDER DATE</th>
                      {/* <th>TOTAL ORDERED ITEM</th> */}
                      <th>ORDER STATUS</th>
                      <th>PAYMENT STATUS</th>
                      {/* <th>INVOICE</th> */}
                      <th>ACTION</th>
                    </tr>
                  </thead>

                  <tbody>
                    {order?.map((val, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            color: `${
                              val.isDeliveredStatus === "REJECTED" ? "red" : ""
                            }`,
                          }}
                        >
                          {/* <td className="py-1">{val?.user?.name}</td> */}
                          <td>{val?.user?.phone}</td>
                          <td>{val?.orderNumber}</td>
                          <td>
                            {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                          </td>

                          {/* <td>{val.totalitems}</td> */}
                          <td>
                            {val.isDeliveredStatus === "DELIVERED" ? (
                              <label class="badge badge-success">
                                {val?.isDeliveredStatus}
                              </label>
                            ) : (
                              <label class="badge badge-danger">
                                {val?.isDeliveredStatus}
                              </label>
                            )}
                          </td>
                          <td>
                            {val.isPaid ? (
                              <label class="badge badge-success">PAID</label>
                            ) : (
                              <label class="badge badge-danger">UNPAID</label>
                            )}
                          </td>
                          {/* <td> */}
                            {/* <Link
                              to={`/invoice/${val?._id}`}
                              // target="_blank"
                              className="btn btn-primary"
                            >
                              <i
                                class="fa-solid fa fa-file-invoice menu-icon "
                                style={{ color: "white" }}
                              ></i>
                            </Link> */}
                            {/* {val?.user?.phone} */}
                          {/* </td> */}
                          <td>
                            <Link
                              className="btn btn-primary"
                              to={`/editorder/${val?._id}`}
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewFranchiseListOrder;
