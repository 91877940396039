import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// import { confirmAlert } from "react-confirm-alert"; // Import
// import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import loaderimg from "../../assets/image/loader.gif";

const URL = process.env.REACT_APP_URL;

const initialState = {
  accountholder: "",
  accountnumber: "",
  branch: "",
  ifsc: "",
};

const BankDetail = () => {
  const [values, setValues] = useState(initialState);
  const [isEditClick, setisEditClick] = useState(false);
  const [showloader, setShowLoader] = useState("none");

 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

const EditData = async () =>{
  setisEditClick(!isEditClick);
}

  const getdata = async () => {
    setShowLoader("block")
    const getbank = await axios.get(`${URL}/admin/getBankDetails`);
    const SingleData1 = await getbank;
    setValues(SingleData1.data.data);
    setShowLoader("none")
  };
  useEffect(() => {
    getdata(); // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const { accountholder, accountnumber, branch, ifsc } = values;
    if (!accountholder || !accountnumber || !branch || !ifsc) {
      toast.error("Please fill out all fields");
      return;
    }
    updatedata(values);
  };

  const updatedata = async (values) => {
    setShowLoader("block");
    const url = `${URL}/admin/updateBankDetails`;
     await axios
      .patch(url, values)
      .then((result) => {
        setShowLoader("none");
        toast.success("Data Updated Successful...");
        EditData();
      })
      .catch(() => {
        toast.error("Failed...");
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
     
      <div className="row">
       {isEditClick?( <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {isEditClick ? "EDIT BANK DETAIL": null}
              </h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Account Holder</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Account Holder"
                    name="accountholder"
                    value={values?.accountholder}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Account Number"
                    name="accountnumber"
                    value={values?.accountnumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Branch</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Branch"
                    name="branch"
                    value={values?.branch}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Ifsc Code"
                    name="ifsc"
                    value={values?.ifsc}
                    onChange={handleChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>):(<div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                {isEditClick ? null : "BANK DETAIL"}
              </h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Account Holder</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Account Holder"
                    name="accountholder"
                    value={values?.accountholder}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Account Number"
                    name="accountnumber"
                    value={values?.accountnumber}
                    onChange={handleChange}  readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Branch</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Branch"
                    name="branch"
                    value={values?.branch}
                    onChange={handleChange}  readOnly
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputUsername1">Ifsc Code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Ifsc Code"
                    name="ifsc"
                    value={values?.ifsc}
                    onChange={handleChange}  readOnly
                  />
                </div>
                
                </form>
                {isEditClick ? (<button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>) : (<button onClick={EditData} className="btn btn-primary mr-2">
                  EDIT DATA
                </button>)}
                
              
            </div>
          </div>
        </div>)}
       

          

      </div>
    </div>
  );
};

export default BankDetail;
