import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;

const ViewPdf = () => {
  const [pdftype, setpdftype] = useState("");
  const [title, settitle] = useState("");
  const [image, setImage] = useState(false);
  const [item, setItems] = useState([]);
  const [showloader, setShowLoader] = useState("none");
  const [franchise, setfranchise] = useState([]);
  const [documentTo, setdocumentTo] = useState("");
  const inputRef = useRef(null);

  const [isEditClick, setisEditClick] = useState(false);
  const [editId, seteditId] = useState("");
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const editpage = async (editid) => {

    const SingleData = await axios.get(
      `${URL}/admin/getSingleFiles/${editid}`
    );
    const SingleData1 = await SingleData;
    setisEditClick(true);
    seteditId(editid);
    setpdftype(SingleData1?.data?.data?.pdftype);
    settitle(SingleData1?.data?.data?.title);

    if (SingleData1?.data?.data?.documentfor_user_status) {
      setdocumentTo(SingleData1?.data?.data?.documentfor_user);
    } else {
      setdocumentTo('ALL');
    }

  };

  const resetFileInput = () => {
    // 👇️ reset input value
    inputRef.current.value = null;
  };
  const getAllFranchiseUser = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/users/getAllUser`);
    const Data1 = await getData;
    setfranchise(Data1?.data?.data);
    setShowLoader("none");
    console.log(Data1?.data?.data);
  };

  const clear = () => {
    settitle('');
    setpdftype('');
    resetFileInput();
    setImage('');
    setdocumentTo('');
    setisEditClick(false);
    seteditId("");
  };
  const handleChange = (e) => {

    setImage(e.target.files[0]);
  };
  const Handlesetpdftype = (e) => {
    const value = e.target.value;
    setpdftype(value);
  };
  const handleChangeTitle = (e) => {
    settitle(e.target.value);
  }
  const handleApi = () => {
    //call the api
    if (!isEditClick) {
      if (!image) {
        toast.error("Please Select Pdf...");
        return;
      }
    }

    if (!pdftype) {
      toast.error("Please Select Pdf Type...");
      return;
    }
    if (!title) {
      toast.error("Please Enter Title...");
      return;
    }
    if (!documentTo) {
      toast.error("Please Enter User...");
      return;
    }

    if (isEditClick) {
      // Update Query

      const url = `${URL}/upload_files&update/${editId}`;
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      if (image) {
        formData.append("file", image, Date.now() + '.pdf');
      }
      formData.append("pdftype", pdftype);
      formData.append("title", title);
      formData.append("documentfor_user", documentTo);

      axios
        .patch(url, formData, config)
        .then((result) => {
          console.log(result.data);
          toast.success("Data Added Successful...");
          getAllData();
          settitle('');
          setpdftype('');
          resetFileInput();
          setImage('');
          setdocumentTo('');
          setisEditClick(false);
          seteditId("");

        })
        .catch((error) => {
          alert("service error");
          console.log(error);
        });
    } else {
      // Add Query
      const url = `${URL}/upload_files`;
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      formData.append("file", image, Date.now() + '.pdf');
      formData.append("pdftype", pdftype);
      formData.append("title", title);
      formData.append("documentfor_user", documentTo);

      axios
        .post(url, formData, config)
        .then((result) => {
          console.log(result.data);
          toast.success("Data Added Successful...");
          getAllData();
          settitle('');
          setpdftype('');
          resetFileInput();
          setImage('');
          setdocumentTo('');

        })
        .catch((error) => {
          alert("service error");
          console.log(error);
        });

    }

  };

  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllFiles`);
    const Data1 = await getData;
    setItems(Data1?.data?.data);
    setShowLoader("none");
    console.log(Data1?.data?.data);
  };

  useEffect(() => {
    getAllData();
    getAllFranchiseUser();
  }, []);

  // delete data start

  const delData = async (id) => {
    const response = await axios.delete(`${URL}/admin/deletefile/${id}`);
    if (response.statusText === "OK") {
      toast.success("Delete Successful...");
      getAllData();
    }
  };

  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure you want to delete this Data.",
      buttons: [
        {
          label: "Yes",
          onClick: () => delData(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  // delete data end
  const handleSendTo = async (e) => {
    const value = e.target.value;
    setdocumentTo(value);
  };



  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* first */}

        <div className="col-lg-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">PDF</h4>
              <p className="card-description">Total PDF : {item?.length}</p>
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>TITLE</th>
                      <th>LINK</th>
                      <th>TYPE</th>
                      <th>USER</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.map((items, index) => {
                      return (
                        <tr key={index}>
                          <td># {index + 1} </td>
                          <td>{items?.title} </td>
                          <td>
                            <a
                              href={items?.url}
                              target="_blank"
                              download
                              rel="noreferrer"
                            >
                              PDF VIEW
                            </a>
                          </td>
                          <td>{items?.pdftype}</td>
                          <td>{items?.documentfor_user?.name}</td>
                          <td>
                            {/* <Link
                              className="btn btn-primary"
                              onClick={() => confirmDelete(items._id)}
                            >
                              {" "} */}
                            <i class="fa-solid fa-trash menu-icon" onClick={() => confirmDelete(items._id)}></i>
                            {/* </Link> */}
                            <i
                              class="fa-solid fa-edit menu-icon"
                              style={{ marginLeft: "10px" }}
                              onClick={() => editpage(items._id)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* first */}

        {/* SECOND FORM */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">  {isEditClick ? "EDIT PDF" : "ADD PDF"} </h4>
              <p className="card-description"></p>

              <div className="form-group">
                <label for="exampleInputUsername1">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputUsername1"
                  onChange={handleChangeTitle}
                  name="title"
                  value={title}
                />
              </div>
              <div className="form-group">
                <label for="exampleInputUsername1">PDF FOR</label>
                <select
                  className="form-control"
                  id="exampleSelectGender"
                  name="pdftype"
                  onChange={Handlesetpdftype}
                  value={pdftype}
                >
                  <option>Select</option>
                  <option value="Manual">Manual</option>
                  <option value="Documents">Documents</option>
                </select>
              </div>

              <div className="form-group">
                <label for="exampleInputEmail1">
                  Send To
                </label>
                <select
                  className="form-control"
                  id="exampleSelectGender"
                  name="documentfor_user"
                  onChange={handleSendTo}
                  value={documentTo}
                >
                  <option>Select</option>
                  <option value="ALL">ALL</option>
                  {franchise.map((franchises, index) => {
                    return (
                      <option value={franchises?._id}>
                        {franchises.name}
                      </option>
                    );
                  })}
                  ;
                </select>
              </div>

              <div className="form-group">
                <label for="exampleInputUsername1">PDF UPLOAD</label>

                <input
                  type="file"
                  className="form-control"
                  id="exampleInputUsername1"
                  onChange={handleChange}
                  name="file"
                  ref={inputRef}

                />
              </div>
              <button onClick={handleApi} className="btn btn-primary mr-2">
                Submit
              </button>
              {isEditClick ? (
                <button
                  onClick={() => clear()}
                  className="btn btn-primary mr-2"
                >
                  Clear
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default ViewPdf;