import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/image/logo.png";
const URL = process.env.REACT_APP_URL;

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({ email: "" });
  const [values2, setValues2] = useState({
    id: "",
    resetToken: "",
    newpassword: "",
  });
  const [userid, setUserid] = useState("");
  const [show, setShow] = useState("none");
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const handleChange2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues2({ ...values2, [name]: value });
  };
  const AddData = async (userData) => {
    try {
      const add = await axios.post(`${URL}/admin/forgetpassword`, userData);
      if (add.status === 200) {
        const data = await add?.data?.data;
        const userid = data?.userid;
        setUserid(userid);
        setValues2({ id: userid });
        setShow("block");
        toast.success("Please check Your Email Address For Secret Code");
      }
    } catch (error) {
      toast.error("Details Not Match...");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const { email } = values;
    if (!email) {
      toast.error("All Fields Required");
      return;
    }
    console.log(values);
    AddData(values);
  };
  const AddData2 = async (userData2) => {
    try {
      const add = await axios.post(`${URL}/admin/updatepassword`, userData2);
      if (add.status === 200) {
        navigate("/login");
        toast.success("Update Successfully");
      }
    } catch (error) {
      toast.error("Details Not Match...");
    }
  };

  const onSubmit2 = (e) => {
    e.preventDefault();

    const { id, resetToken, newpassword } = values2;
    if ((!id, !resetToken, !newpassword)) {
      toast.error("All Fields Required");
      return;
    }

    AddData2(values2);
  };
  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo text-center">
                    <img src={Logo} alt="logo" />
                  </div>

                  {/* <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6> */}
                  {/* {userid?():(<form className="pt-3" onSubmit={onSubmit}>)}
                   */}
                  {/* one form */}
                  <div style={{ display: `${userid ? "none" : "block"}` }}>
                    <form className="pt-3" onSubmit={onSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Email"
                          name="email"
                          readonly={userid ? "readonly" : null}
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          FORGET PASSWORD
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* two  form */}
                  <div style={{ display: show }}>
                    <form className="pt-3" onSubmit={onSubmit2}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Email"
                          name="email"
                          readonly={userid ? "readonly" : null}
                          value={values.email}
                        />
                      </div>

                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="exampleInputPassword1"
                          placeholder="Secret Token"
                          name="resetToken"
                          value={values2.resetToken}
                          onChange={handleChange2}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          id="exampleInputPassword1"
                          placeholder="Password"
                          name="newpassword"
                          value={values2.newpassword}
                          onChange={handleChange2}
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          UPDATE PASSWORD
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
