import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import { toast } from "react-toastify";
import Logo from "../../assets/image/logo.png";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import DefaultImg from "../../assets/image/defaultimages/default.png";
const SharedLayout = () => {
  const [isActive, setActive] = useState("inactive");
  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const toggleClass = () => {
    setActive(isActive === "inactive" ? "active" : "inactive");
  };

  const logout = (e) => {
    e.preventDefault();

    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  // check is already token available or not
  const navigate = useNavigate();
  // useEffect(() => {
  //   const adminlocaltoken = localStorage.getItem("token");

  //   if (!adminlocaltoken) {
  //     navigate("/login");
  //     toast.info("Acess Denied...");
  //   }
  // }, []);

  // check is already token available or not

  return (
    <div class="container-scroller">
      <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link class="navbar-brand brand-logo mr-5" to="/dashboard">
            <img src={Logo} class="mr-2" alt="logo" />
          </Link>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <ul class="navbar-nav mr-lg-2"></ul>
          <ul class="navbar-nav navbar-nav-right">
            <li class="nav-item nav-profile dropdown">
              <Link
                class="nav-link dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                <img src={DefaultImg} alt="profile" />
              </Link>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                <Link to='/changepassword' class="dropdown-item" >
                  <i class="ti-key text-primary"></i>
                  Change Password
                </Link>
                <Link class="dropdown-item" onClick={logout}>
                  <i class="ti-power-off text-primary"></i>
                  Logout
                </Link>
              </div>
            </li>
            <li class="nav-item nav-settings d-none d-lg-flex">
              <Link class="nav-link">
                {/* <i class="icon-ellipsis"></i> */}
              </Link>
            </li>
          </ul>
          {/* <button onClick={toggleClass}>toggle</button> */}
          <button
            class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
            onClick={toggleClass}
          >
            <span class="icon-menu" onClick={toggleClass}></span>
          </button>
        </div>
      </nav>

      <div class="container-fluid page-body-wrapper">
        <Sidebar isActive={isActive} onClick={toggleClass}/>
        <div class="main-panel">
          <Outlet />
          <footer class="footer">
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              {/* <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021.  Premium <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap admin template</a> from BootstrapDash. All rights reserved.</span>
            <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="ti-heart text-danger ml-1"></i></span> */}
            </div>
            <div class="d-sm-flex justify-content-center justify-content-sm-between">
              {/* <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Distributed by <a href="https://www.themewagon.com/" target="_blank">Themewagon</a></span>  */}
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
