import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import confirmAlert from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// import { toast } from "react-toastify";
import dateFormat from "dateformat";
// import $ from "jquery";

import loaderimg from "../../assets/image/loader.gif";
const URL = process.env.REACT_APP_URL;

const ViewAllOrder = () => {
  const [order, setOrder] = useState([]);
  const [showloader, setShowLoader] = useState("none");
const[searchterm,setSearchterm]=useState('');

 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************

  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllOrderAdmin`);
    const Data1 = await getData;
    setOrder(Data1.data.data);
    setShowLoader("none");
    console.log(Data1.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const search = (e) =>{
    setSearchterm(e.target.value);
  }
  const filteredorder = order.filter((order) => order.orderNumber.toLowerCase().includes(searchterm.toLowerCase()));

  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>

      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Search By Order Number {searchterm}</h4>
              <div className="row ">
                <div className="col-md-8  mb-2">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    onChange={search}
                    name="search"
                  />
                </div>
                {/* <div className="col-md-3 ">
                  {" "}
                  <button
                    onClick={SubmitRecept}
                    className="btn btn-primary mr-2 "
                  >
                    Submit
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">VIEW ORDERS</h4>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                    <th>ACTION</th>
                      <th>USER NAME</th>

                      <th>ORDER NUMBER</th>
                      <th>ORDER DATE</th>
                      {/* <th>TOTAL ORDERED ITEM</th> */}
                      <th>ORDER STATUS</th>
                      <th>PAYMENT STATUS</th>
                      <th>COURIER RECEPT</th>
                      <th>INVOICE</th>
                     
                    </tr>
                  </thead>

                  <tbody>
                    {filteredorder?.map((val, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            color: `${
                              val.isDeliveredStatus === "REJECTED" ? "red" : ""
                            }`,
                          }}
                        >
                           <td>
                            <Link
                              className="btn btn-primary"
                              to={`/editorder/${val?._id}`}
                            >
                              View
                            </Link>
                          </td>
                          <td className="py-1">{val?.user?.name}</td>

                          <td>{val?.orderNumber}</td>
                          <td>
                            {dateFormat(
                              `${val?.createdAt}`,
                              "mmmm dS, yyyy HH:MM"
                            )}
                          </td>

                          {/* <td>{val.totalitems}</td> */}
                          <td>{val.creditlimitdaysDue}
                            {val.isDeliveredStatus === "DELIVERED" ? (
                            <>  <label class="badge badge-success">
                                {val?.isDeliveredStatus} 
                              </label> <p style={{color:"red"}}> <label class="badge badge-info mt-1">
                              {val?.creditlimitdays > 0 ? `Amt Pending ${val?.creditlimitdays} Days`: null }
                              </label></p></>
                            ) : (
                              <label class="badge badge-danger">
                                {val?.isDeliveredStatus}
                              </label>
                            )}
                          </td>

                          <td>
                            {val.isPaid ? (
                              <label class="badge badge-success">PAID</label>
                            ) : (
                              <label class="badge badge-danger">UNPAID</label>
                            )}
                          </td>
                          <td>
                            {val.courierrecept_imagename ? (
                              <Link
                                to={val.courierrecept_imageurl}
                                target="_blank"
                              >
                                {" "}
                                <label class="badge badge-success">
                                  DOWNLOAD
                                </label>
                              </Link>
                            ) : null}
                          </td>
                          <td>
                          {val.isInvoicAvailable ? (
                              <Link
                                to={val.pdfurl}
                                target="_blank"
                              >
                                {" "}
                                <label class="badge badge-success">
                                  DOWNLOAD
                                </label>
                              </Link>
                            ) : null}
                            {/* <Link
                              to={`/invoice/${val?._id}`}
                              // target="_blank"
                              className="btn btn-primary"
                            >
                              <i
                                class="fa-solid fa fa-file-invoice menu-icon "
                                style={{ color: "white" }}
                              ></i>
                            </Link> */}
                            {/* {val?.user?.phone} */}
                          </td>

                       
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewAllOrder;
