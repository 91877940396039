import React from "react";
//import "./invoice.css";
import "./InvoiceNew.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import Logo from "../assets/image/logo.png";
import axios from "axios";
const URL = process.env.REACT_APP_URL;

const Invoicebackup = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const pdfRef = useRef();
  const [qr, setQr] = useState("");
  let sum = 0;


  async function handleDownload() {
    const contentCanvas = await html2canvas(
      document.getElementById("content"),
      {
        width: 1024, // <- here
        windowWidth: 1024,
      }
    );
    const image = contentCanvas.toDataURL("image/png");
    var doc = new jsPDF();
    doc.addImage(image, "png", 0, 0);
    doc.save('invoice.pdf');
    const pdf = doc.output('blob');
    send(pdf); //send pdf called here
  }


  const send = async (pdf) => {
     alert("Pdf Generated Successfully");
     const url = `${URL}/user/Uploadpdf/uploadPdfs/${id}`;
     const config = {
       headers: {
         "content-type": "application/pdf",
       },
     };

     var data = new FormData();
    data.append("file", pdf);
    axios.patch(url, data, config).then((result) => {
      console.log(result.data);
    });
    // pdf send
  };

 

  const getAllData = async () => {
    fetch(`${URL}/admin/getSingleOrderdetail/${id}`)
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setOrder(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQrCode = async () => {
    const getqr = await axios.get(`${URL}/admin/getsingleQr`);
    const getdata = await getqr;
    setQr(getdata?.data?.data);
    console.log(getdata?.data?.data);
  };

  useEffect(() => {
    getAllData();
    getQrCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="wrapper" id="content" ref={pdfRef}>
        <Link
          className="btn btn-primary mb-3"
          data-title="PDF"
          onClick={handleDownload}
        >
          Download Pdf
        </Link>
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-primary ml-2 mb-3">
              Print this out!
            </button>
          )}
          content={() => pdfRef.current}
        />
        <div className="invoice_wrapper">
          <div className="header border-bottom border-5 border-primary">
            <div className="logo_invoice_wrap border-bottom border-5 border-primary">
              <div className="logo_sec " style={{ width: "100px" }}>
                <img src={Logo} alt="code logo" className="w-100" />
              </div>
              <div className="invoice_sec">
                <p className="invoice bold">INVOICE</p>
                <p className="invoice_no">
                  <span className="bold">Invoice</span>
                  <span>#{order?.orderNumber}</span>
                </p>
                <p className="date">
                  <span className="bold">Date</span>
                  <span> {new Date().toLocaleString() + ""}</span>
                </p>
              </div>
            </div>

            <div className="bill_total_wrap">
              <div className="bill_sec">
                <p>Bill To</p>
                <p className="bold name"> {order?.user?.name}</p>
                <span>
                  City : Indore
                  <br />
                  Phone : +91 {order?.user?.phone}
                </span>
              </div>
              <div className="total_wrap">
                {/* <p>Total Amount</p>
	          		<p className="bold price">RS: {sum}</p> */}
              </div>
            </div>
          </div>
          <div className="body ">
            <div className="main_table border-bottom border-5 border-primary">
              <div className="table-responsive ">
                <table className="table table-striped table-border border-3 border-b-2 brc-default-l1">
                  <thead
                    className="bold border-bottom border-5 border-primary"
                    style={{ background: "#f07c00" }}
                  >
                    <tr
                      className="text-black border-primary border-5"
                      style={{ background: "#f07c00" }}
                    >
                      <th className="opacity-2">#</th>
                      <th>Description</th>
                      <th>Qty</th>
                      <th>Unit Price</th>
                      <th width="140">Amount</th>
                    </tr>
                  </thead>

                  <tbody className="text-95 text-secondary-d3">
                    <tr></tr>

                    {order?.orderItems?.map((dat, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {dat?.itemid?.name}</td>
                          <td>{dat?.qty}</td>
                          <td>
                            {dat?.itemid?.price} {dat?.itemid?.unit}
                          </td>
                          <td>{dat?.qty * dat?.itemid?.price}</td>
                          <div style={{ display: "none" }}>
                            {" "}
                            {(sum += Number(dat?.qty * dat?.itemid?.price))}
                          </div>
                        </tr>
                      );
                    })}
                    {/* <tr>
                        <td>1</td>
                        <td>Domain registration</td>
                        <td>2</td>
                        <td className="text-95">$10</td>
                        <td className="text-secondary-d2">$20</td>
                      </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="paymethod_grandtotal_wrap ">
              <div className="paymethod_sec">
                <p className="bold">Payment Method</p>
                <p>Scan this QR code to pay this amount .</p>
              </div>
              <div className="grandtotal_sec">
                <p className="bold">
                  <span>Grand Total</span>
                  <span>RS: {sum}</span>
                </p>
              </div>
            </div>
            <div className="paymethod_grandtotal_wrap ">
              <img
                src={qr?.image}
                style={{ width: "15%" }}
                className="ml-4"
                alt="img"
              />
            </div>
          </div>
          <div className="footer border-bottom border-5 border-primary ">
            <div className="d-flex justify-content-between">
              <div className="terms">
                <p className="tc bold">Terms & Conditions</p>
                <p>Please pay Amount within 10 days of order...</p>
              </div>
              <div>
                <p>Signature</p>
                <p>Thank you and Best Wishes</p>
              </div>
            </div>
            <div className="d-flex border-top border-2 border-primary ">
              <div class="contact-info mt-2 ">
                <Link href="tel:+55-4XX-634-7071" className="ml-2">
                  <i class="fa fa-phone"></i> +91 1234567891
                </Link>
                <Link href="tel:info@namo.com" className="ml-2">
                  <i class="fa fa-envelope"></i> info@namo.com
                </Link>
                <Link href="tel:info@namo.com" class="mr-0 d-none-580 ml-2">
                  <i class="fa fa-map-marker"></i> Sudama Nager
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoicebackup;
