import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_URL;

const initialstate = {
  title: "",
  description: "",
};

const EditNotification = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  const [image, setImage] = useState("");

    // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************


  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const getAllData = async () => {
    const SingleData = await axios.get(
      `${URL}/admin/getSingleNotification/${id}`
    );
    const SingleData1 = await SingleData;
    setValues(SingleData1.data.data);
    console.log(SingleData1.data);
  };

  useEffect(() => {
    getAllData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const UpdateData = async () => {
    const url = `${URL}/admin/notification/updateSingleNotification/${id}`;
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const formData = new FormData();
    // if (image) {
    //   formData.append("file", image);
    //   formData.append("title", values.title);
    //   formData.append("description", values.description);
    // } else {
      formData.append("title", values.title);
      formData.append("description", values.description);
    // }

    axios
      .patch(url, formData, config)
      .then((result) => {
        console.log(result.data);
        toast.success("Data Update Successful...");
        navigate("/viewnotification");
        getAllData();
      })
      .catch((error) => {
        alert("service error");
        console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { title, description } = values;
    if (!title || !description) {
      toast.error("Please fill out all fields");
      return;
    }
    UpdateData(values);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className="content-wrapper">
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">EDIT NOTIFICATION</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label for="exampleInputUsername1">TITLE</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Enter Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </div>

                {/* <div className="form-group">
                  <label for="exampleInputEmail1">Image</label>
                  <img
                    className="py-5 w-100"
                    src={values?.imageurl}
                    alt="img"
                  />

                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputUsername1"
                    onChange={handleImageChange}
                    name="file"
                  />
                </div> */}
                
                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default EditNotification;
