import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Register, Login, Error } from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminSharedLayout from "./pages/admin/SharedLayout";
import Dashboard from "./pages/admin/Dashboard";
import ViewAllOrder from "./pages/admin/ViewAllOrder";
import EditOrder from "./pages/admin/EditOrder";
import InvoiceOrder from "./components/Invoice";
import ViewItem from "./pages/admin/ViewItem";
import ViewPdf from "./pages/admin/ViewPdf";
import ViewNotification from "./pages/admin/ViewNotification";
import EditNotification from "./pages/admin/EditNotification";
import AddNotification from "./pages/admin/Addnotification";
import AddFranchise from "./pages/admin/AddFranchise";
import EditFranchise from "./pages/admin/EditFranchise";
import ViewFranchise from "./pages/admin/ViewFranchise";
import ViewFranchiseorder from "./pages/admin/ViewFranchiseOrderDetail";
import ViewFranchiseListOrder from "./pages/admin/ViewFranchiseListOrder";
import ProtectedRoute from "./components/ProtectedRoute";
import MultiPages from "./pages/admin/ViewMultipages";
import ViewStaff from "./pages/admin/ViewSubStaff";
import AddStaff from "./pages/admin/AddStaff";
import EditStaff from "./pages/admin/EditSubStaff";
import ForgetPassword from "./pages/Forgetpassword";
import QrCode from "./pages/admin/QrCode";
import Consumptiondetail from "./pages/admin/Consumptiondetail";
import Feedback from "./pages/admin/Feedback";

import BankDetail from "./pages/admin/BankDetail";
import AlertMobileUser from "./pages/admin/MobileAlertStaff";
import ChangePassword from "./pages/admin/ChangePassword";
import Editquantity from "./pages/admin/Editquantity";

function App() {
  return (
    <BrowserRouter basename="/">
      <ToastContainer />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="*" element={<Error />} />

        {/* private start */}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<AdminSharedLayout />}>
            {/* Admin file create here start */}
            <Route index path="dashboard" element={<Dashboard />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="vieworder" element={<ViewAllOrder />} />
            <Route path="editorder/:id" element={<EditOrder />} />
            <Route path="editquantity/:id" element={<Editquantity />} />
            <Route path="viewitem" element={<ViewItem />} />
            <Route path="viewpages" element={<MultiPages />} />
            <Route path="qrcode" element={<QrCode />} />
            <Route path="viewpdf" element={<ViewPdf />} />
            <Route path="viewnotification" element={<ViewNotification />} />
            <Route path="editnotification/:id" element={<EditNotification />} />
            <Route path="addnotification" element={<AddNotification />} />

            <Route path="addfranchise" element={<AddFranchise />} />
            <Route path="editfranchise/:id" element={<EditFranchise />} />

            <Route path="viewfranchise" element={<ViewFranchise />} />
            <Route
              path="viewfranchiseListOrder/:id"
              element={<ViewFranchiseListOrder />}
            />
            <Route
              path="viewfranchise_order/:id"
              element={<ViewFranchiseorder />}
            />

            <Route path="viewstaff" element={<ViewStaff />} />
            <Route path="addstaff" element={<AddStaff />} />
            <Route path="editstaff/:id" element={<EditStaff />} />

            <Route path="invoice/:id" element={<InvoiceOrder />} />

            <Route
              path="consumptiondetail/:id"
              element={<Consumptiondetail />}
            />

            <Route path="feedback" element={<Feedback />} />
           
            <Route path="bankdetail" element={<BankDetail />} />
            <Route path="alertmobilestaff" element={<AlertMobileUser />} />




            {/* Admin file create here End */}
          </Route>
        </Route>
        {/* private end */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
