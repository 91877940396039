import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import confirmAlert from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import dateFormat from "dateformat";
 import { toast } from "react-toastify";
import DefaultImg from "../../assets/image/defaultimages/default.png";
import loaderimg from "../../assets/image/loader.gif";

const URL = process.env.REACT_APP_URL;



const ViewSubStaff = () => {
  const [staff, setStaff] = useState([]);
  const [showloader, setShowLoader] = useState("none");
  const [loginstaffinfo, setLoginStaffinfo] = useState("");
  // const [adminid, setadminid] = useState("");


  // const  getlocaldata = async ()=>{
  //   setadminid(localStorage.getItem("adminid"));
  // }
  
  // useEffect(() => {getlocaldata() }, []);
  

  // const getloginstaffinfo = async () => {
  //   setShowLoader("block");
  //  const getData2 = await axios.get(`${URL}/admin/getstaffinfo/${adminid}`);
  //   const Data2 = await getData2;
  //   setLoginStaffinfo(Data2?.data?.data);
  //   setShowLoader("none");
  //   console.log(Data2?.data?.data);
  // };
 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getAllStaff`);
    const Data1 = await getData;
    setStaff(Data1?.data?.data);
    setShowLoader("none");
    console.log(Data1?.data?.data);
  };

  useEffect(() => {
    getAllData();
    // getloginstaffinfo();
  }, []);

  const handleStatus = async (id, e) => {
    const value = e.target.value;

    const patchdata = await axios.patch(
      `${URL}/admin/changeAdminStatus/${id}`,
      { isBlocked: value }
    );
    if (patchdata?.status === 200) {
      toast.success("Update Successful...");
      getAllData();
    }
  };
  return (
    <div className="content-wrapper">
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                SUB STAFF
              </h4>
              <Link to="/addstaff" className="btn btn-primary">
                ADD STAFF 
              </Link>
              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>IMAGE</th>
                      <th>NAME</th>
                      <th>PHONE</th>
                      <th>TYPE</th>
                      <th>STATUS</th>
                      <th>DATE</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {staff?.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1"># {index}</td>
                          <td class="py-1">
                            {val?.imageurl ? (
                              <img src={val?.imageurl} alt="images" />
                            ) : (
                              <img src={DefaultImg} alt="images"></img>
                            )}
                          </td>
                          <td>{val?.name}</td>
                          <td>{val?.phone}</td>
                          <td>
                            <span style={{ color: "red" }}>
                              {val?.loginType.toUpperCase()}
                            </span>
                          </td>
                          <td>
                            {" "}
                            {val?.loginType.toUpperCase() === "SUPERADMIN" ? (
                              "SUPER ADMIN STATUS ALWAYS ACTIVE"
                            ) : (
                              <select
                                className="form-control"
                                id="exampleSelectGender"
                                name="isBlocked"
                                onChange={(e) => handleStatus(val?._id, e)}
                              >
                                <option>Select</option>
                                <option
                                  value="true"
                                  selected={
                                    val?.isBlocked === true ? "selected" : null
                                  }
                                >
                                  InActive
                                </option>
                                <option
                                  value="false"
                                  selected={
                                    val?.isBlocked === false ? "selected" : null
                                  }
                                >
                                  Active
                                </option>
                              </select>
                            )}
                          </td>
                          <td>
                            {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                          </td>
                          <td>
                          <Link
                                className="btn btn-primary"
                                to={`/editstaff/${val?._id}`}
                              >
                                {" "}
                                <i class="fa-solid fa-edit menu-icon"></i>
                              </Link>

                            {/* {val.loginType !== "SuperAdmin" ? (
                              <Link
                                className="btn btn-primary"
                                to={`/editstaff/${val?._id}`}
                              >
                                {" "}
                                <i class="fa-solid fa-edit menu-icon"></i>
                              </Link>
                            ) : null} */}
                          
                            {/* {val.loginType === 'SuperAdmin' && loginstaffinfo.loginType === 'SuperAdmin' ? (
                              <Link
                                className="btn btn-primary"
                                to={`/editstaff/${val?._id}`}
                              >
                                {" "}
                                <i class="fa-solid fa-edit menu-icon"></i>
                              </Link>
                            ) : null} */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default ViewSubStaff;
