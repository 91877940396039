import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_URL;

const initialstate = {
  name: "",
  username:"",
  email: "",
  phone: "",
  password: "",
  companyname: "",
  gstnumber: "",
  address: "",
};

const EditFranchise = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [values, setValues] = useState(initialstate);

  const getdata = async () => {
    const getfranchise = await axios.get(`${URL}/users/getSingleUser/${id}`);
    const SingleData1 = await getfranchise;
    setValues({
      name: SingleData1?.data?.data?.name,
      email: SingleData1?.data?.data?.email,
      username: SingleData1?.data?.data?.username,
      phone: SingleData1?.data?.data?.phone,
      companyname: SingleData1?.data?.data?.companyname,
      gstnumber: SingleData1?.data?.data?.gstnumber,
      address: SingleData1?.data?.data?.address,
    });
    console.log(SingleData1.data);
  };

  useEffect(() => {
    getdata(); // eslint-disable-next-line
  }, []);

  // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  //- //******************************** Token Send ********************************

  const CreateData = async (formData) => {
    const url = `${URL}/users/updateSingleUser/${id}`;
    axios
      .patch(url, formData)
      .then((result) => {
        console.log(result.data);
        toast.success("Data Updated Successful...");
        navigate("/viewfranchise");
      })
      .catch((error) => {
        alert("Data Not Found");
        console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    //Dont Remove password
    const { name, email, phone, password, companyname, gstnumber, address } =
      values;

    if (!name || !email || !phone || !companyname || !gstnumber || !address) {
      toast.error("Please fill out all fields");
      return;
    }
    CreateData(values);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  return (
    <div className="content-wrapper">
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">UPDATE FRANCHISE</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">NAME</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter NAME"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">ADDRESS</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">Email</label>
                      <div class="col-sm-9">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Enter Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">PASSWORD</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          placeholder="Password"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">PHONE</label>
                      <div class="col-sm-9">
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          pattern="[1-9]{1}[0-9]{9}"
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">
                        COMPANY NAME
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="companyname"
                          value={values.companyname}
                          onChange={handleChange}
                        />
                      </div>
                    </div>



                    
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">GST NUMBER</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name="gstnumber"
                          value={values.gstnumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div className="form-group row">
                      <label class="col-sm-3 col-form-label">USER NAME</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          readonly
                          className="form-control"
                          id="exampleInputEmail1"
                          name="username"
                          value={values.username}
                        
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default EditFranchise;
