import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_URL;

const initialstate = {
  oldpassword: "",
  newpassword: "",
};
const ChangePassword = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialstate);
  const [userid, setuserid] = useState("");

 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************


  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    const { oldpassword, newpassword } = values;

    if (!newpassword || !oldpassword) {
      toast.error("Please fill out all fields");
      return;
    }
    CreateData();
  };

  const CreateData = async () => {
    const url = `${URL}/admin/updatepasswordAdmin`;
    const { newpassword, oldpassword } = values;

    axios
      .patch(url, { newpassword, oldpassword, id: userid })

      .then((result) => {
        toast.success("Password Update Successful...");
        navigate("/viewstaff");
      })
      .catch((error) => {
        toast.error("Incorrect Old  Password..");
        console.log(error);
      });
  };

  useState(() => {
    setuserid(localStorage.getItem("adminid"));
  }, []);

  return (
    <div className="content-wrapper">
      <div className="row">
        {/* SECOND FORM */}
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Change Password</h4>
              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label for="exampleInputUsername1">Old Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputUsername1"
                    placeholder="Enter Old Password"
                    name="oldpassword"
                    value={values.oldpassword}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label for="exampleInputEmail1">New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter New Password"
                    name="newpassword"
                    value={values.newpassword}
                    onChange={handleChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* SECOND FORM */}
      </div>
    </div>
  );
};

export default ChangePassword;
