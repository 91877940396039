import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import dateFormat from "dateformat";

import loaderimg from "../../assets/image/loader.gif";
import Modal from "../../components/Modal";

const URL = process.env.REACT_APP_URL;

const Feedback = () => {
  const [feedback, setfeedback] = useState([]);
  const [showloader, setShowLoader] = useState("none");
  const [modalactive, setModalActive] = useState("none"); //model
  const [details, setdetails] = useState(""); //model
 // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const setModalShow = (details, id) => {
    setModalActive("block");
    setdetails(details);
    updatereadstatus(id);
  };

  const setModalHide = () => {
    setModalActive("none");
    setdetails("");
  };
  const updatereadstatus = async (id) => {
    // only update
    await axios
      .patch(`${URL}/admin/updatefeedback_readstatus/${id}`)
      .then((response) => {
        getAllData();
      });
  };
  const getAllData = async () => {
    setShowLoader("block");
    const getData = await axios.get(`${URL}/admin/getfeedbacks`);
    const Data1 = await getData;
    setfeedback(Data1.data.data);
    setShowLoader("none");
    console.log(Data1.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div className="content-wrapper">
      <Modal
        modalactive={modalactive}
        title="Feedback Details"
        description={details}
        setModalHide={setModalHide}
      />
      <div className="loader-container " style={{ display: showloader }}>
        <img src={loaderimg} alt="" className="loaderImage" />
      </div>
      <div className="row">
        {/* // start */}
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">View Feedbacks</h4>

              {/* <p className="card-description">Add className</p> */}
              <div className="table-responsive" id="collapse1">
                <table className="table table-striped tableFixHead">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>NAME</th>
                      <th>DATE</th>
                      <th>TITLE</th>
                      <th>READ STATUS</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {feedback?.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1"># {index + 1}</td>
                          <td>{val?.userid?.name}</td>
                          <td>
                            {dateFormat(`${val?.createdAt}`, "mmmm dS, yyyy")}
                          </td>
                          <td>{val?.title}</td>
                          <td>
                            {val.readstatus ? (
                              <label class="badge badge-success">READ</label>
                            ) : (
                              <label class="badge badge-danger">UNREAD</label>
                            )}
                          </td>
                          <td>
                            <Link
                              className="btn btn-primary"
                              onClick={() =>
                                setModalShow(val?.feedback, val._id)
                              }
                            >
                              <i class="fa-solid fa-eye menu-icon"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* // end */}
      </div>
    </div>
  );
};

export default Feedback;
