import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const URL = process.env.REACT_APP_URL;

const initialState = {
  id: "",
  image: "",
  file: "",
  imagename: "",
};

const QrCode = () => {
  const [values, setValues] = useState(initialState);

   // //******************************** Token Send ********************************

  // const token = localStorage.getItem("token");
  // const config = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };

  // //******************************** Token Send ********************************
  const UpdateData = async (userData) => {
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const formData = new FormData();
    if (values.file !== "") {
      formData.append("file", values.file);
    }

    await axios
      .patch(`${URL}/admin/qr/updateQr`, formData, config)
      .then((result) => {
        console.log(result.data);
        toast.success("Data Added Successful...");
        getAllData();
      })
      .catch((error) => {
        alert("service error");
        console.log(error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    UpdateData(values);
  };
  const handleFileChange = (e) => {
    const name = "file";
    const value = e.target.files[0];
    setValues({ ...values, [name]: value });
  };

  const getAllData = async () => {
    const getData = await axios.get(`${URL}/admin/getallQr`);
    const Data1 = await getData;
    setValues(Data1?.data?.data[0]);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Update Qr Code </h4>

              <p className="card-description"></p>
              <form className="forms-sample" onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">IMAGE</label>
                  <img src={values?.image} alt="img" />
                      
                  <input
                    type="file"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="page unit"
                    name="file"
                    onChange={handleFileChange}
                  />
                </div>

                <button type="submit" className="btn btn-primary mr-2">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCode;
